<template>
  <div>
    <div v-if="displayTable" class="d-flex">
      <b-button class="mb-2 mr-2" variant="primary" @click="addUser">
        Ajouter utilisateur
      </b-button>
      <div class="user-fixed-search">
        <b-input-group class="input-group-merge w-100 round">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" class="text-muted" />
          </b-input-group-prepend>
          <b-form-input v-model="searchQuery" placeholder="Rechercher..." />
        </b-input-group>
      </div>
    </div>
    <div v-else>
      <b-button class="mb-2" variant="primary" @click="backToTable">
        Retour
      </b-button>
    </div>
    <b-card no-body class="mb-0">
      <div v-if="displayTable">
        <b-table :fields="fields" :responsive="true" :items="filteredUsers" :per-page="perPage"
          :current-page="currentPage">
          <template v-if="filteredUsers.length === 0" #top-row>
            <p class="d-flex justify-content-center">
              Aucun résultat trouvé
            </p>
          </template>
          <template #cell(status)="data">
            <div class="form-check form-switch">
              <div>
                <span v-if="data.value === true">Actif</span>
                <span v-else>Inactif</span>
              </div>
            </div>
          </template>
          <template #cell(backup)="data">
            <div class="form-check form-switch">
              <div>
                <span v-if="data.value === true">
                  Oui
                  <feather-icon v-if="data.value === true" icon="CheckIcon" class="isBackup" />
                </span>
                <span v-else>Non</span>
              </div>
            </div>
          </template>
          <template #cell(roles)="data">
            <span>{{ data.item.roles[0].split('.')[1] }}</span>
          </template>
          <template #cell(telephone)="data">
            <div v-if="data.item.telephone">
              <span>{{ data.item.telephone }}</span>
              <feather-icon v-if="!data.item.phoneNumberConfirmed" icon="XIcon" class="checkCircle" />
            </div>
          </template>
          <template #cell(email)="data">
            <div v-if="data.item.email">
              <span>{{ data.item.email }}</span>
              <feather-icon v-if="!data.item.emailConfirmed" icon="XIcon" class="checkCircle" />
            </div>
          </template>
          <template #cell(actions)="data">
            <b-button @click="saveUserData(data.item)">
              Modifier
            </b-button>
          </template>
        </b-table>
      </div>
      <div v-else>
        <userForm :user-data="userData" :email="userData.email" :phone="userData.telephone" :mode="triggeredButton"
          @submit="onFormSubmit" />
      </div>
    </b-card>
    <span v-if="displayTable" class="pagination-panel">
      <b-form-select v-model="perPage" class="pagination-panel__select" :options="displayResult" />
      <b-pagination v-model="currentPage" :total-rows="filteredUsers.length" :per-page="perPage"
        aria-controls="my-table" />
      <p class="displaynbResult">Affichage ligne {{ perPage * currentPage }} sur {{ filteredUsers.length }}</p>
    </span>
  </div>
</template>
<script>
import store from '@/store' // eslint-disable-line

import { ref, computed } from '@vue/composition-api'
import {
  BCard,
  BTable,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
  BPagination,
  BFormSelect,
} from 'bootstrap-vue'
import SearchMatcher from '@/utils/SearchMatcher'
import userForm from './UserForm.vue'

export default {
  components: {
    BCard,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BFormSelect,
    BTable,
    BButton,
    userForm,
    BPagination,
  },
  setup() {
    const displayTable = ref(true)
    const userData = ref({})
    const triggeredButton = ref('')
    const currentPage = ref(1)
    const perPage = 5
    const displayResult = [
      5,
      10,
      15,
      20,
    ]

    const users = ref(computed(() => store.getters['users/users'].map(obj => {
      const status = obj.profile.isActive
      const backup = obj.profile.isBackup
      const departement = obj.profile.department
      const roles = obj.roles[0]
      const {
        id,
        lastName: nom,
        firstName: prenom,
        phoneNumber: telephone,
        userName: identifiant,
        actions,
        ...rest
      } = obj

      return {
        id,
        nom,
        prenom,
        departement,
        status,
        backup,
        roles,
        identifiant,
        telephone,
        actions,
        ...rest,
      }
    })))
    if (users.value.length === 0) { store.dispatch('users/fetchUsers') }
    // Search
    const searchQuery = ref('')
    const searchMatcher = computed(() => new SearchMatcher(searchQuery.value))
    const filteredUsers = computed(() => users.value.filter(y => searchMatcher.value.match(
      y.email,
      y.prenom,
      y.nom,
    )))

    function onFormSubmit(data) {
      displayTable.value = data
    }

    function addUser() {
      displayTable.value = false
      triggeredButton.value = 'ajouter'
      userData.value = { status: true }
    }
    function backToTable() {
      displayTable.value = true
    }

    function saveUserData(data) {
      userData.value = data
      triggeredButton.value = 'modifier'
      displayTable.value = false
    }

    const fields = [
      { key: 'nom', sortable: true },
      { key: 'prenom', sortable: true },
      { key: 'identifiant', sortable: true },
      { key: 'roles', sortable: true },
      { key: 'departement', sortable: true },
      { key: 'status', sortable: true },
      { key: 'backup', sortable: true },
      { key: 'telephone', sortable: true },
      { key: 'email', sortable: true },
      { key: 'actions', sortable: true },
    ]

    return {
      displayTable,
      searchQuery,
      searchMatcher,
      filteredUsers,
      displayResult,
      triggeredButton,
      saveUserData,
      userData,
      onFormSubmit,
      backToTable,
      users,
      addUser,
      fields,
      perPage,
      currentPage,
    }
  },
}
</script>

<style lang="scss" scoped>
.pagination {
  margin-top: 15px;
}

.pagination-panel {
  width: auto;
  display: flex;
  align-items: center;

  &__select {
    width: auto;
  }
}

.displaynbResult {
  display: flex;
  width: 100%;
  justify-content: end;
}

.checkCircle {
  margin-left: 15px;
  color: red;
}

.isBackup {
  color: forestgreen;
}
</style>
